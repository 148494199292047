@font-face {
  font-family: 'ppFont';
  src: url('ppFont.eot?iyd40a');
  src: url('ppFont.eot?iyd40a#iefix') format('embedded-opentype'),
  url('ppFont.ttf?iyd40a') format('truetype'),
  url('ppFont.woff?iyd40a') format('woff'),
  url('ppFont.svg?iyd40a#ppFont') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^='pp-icon-'], [class*=' pp-icon-'], .pp-icon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'ppFont' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  position: relative;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  .title & {
    font-size: inherit;
  }
}
.pp-icon-lg {
  font-size: 1.7rem;
}

$success: #00936C;
$default: #c1c9d9;
$success-l: lighten($success, 5%);
$success-d: darken($success, 5%);
$default-l: lighten($default, 5%);
$default-d: darken($default, 5%);

.pp-icon-appeal::before {
  content: '\e901';
}

.pp-icon-arrow-left::before {
  content: '\e902';
}

.pp-icon-arrow-right::before {
  content: '\e903';
}

.pp-icon-arrow-down::before {
  content: '\e903';
  transform: rotate(90deg);
  display: inline-block;
}

.pp-icon-arrow-up::before {
  content: '\e903';
  transform: rotate(-90deg);
  display: inline-block;
}

.pp-icon-user::before {
  content: '\e900';
}

.pp-icon-cancel {
  &::before {
    content: '\e904';
  }
  color: $default;
  &:hover {
    color: $default-l;
  }
  &:active {
    color: $default-d;
  }
}

.pp-icon-check {
  &::before {
    content: '\e905';
  }
  color: $default;
  &:hover {
    color: $default-l;
  }
  &:active {
    color: $default-d;
  }
}

.pp-icon-check_active {
  &::before {
    content: '\e906';
  }
  color: $success;
  &:hover {
    color: $success-l;
  }
  &:active {
    color: $success-d;
  }
}

.pp-icon-check_double {
  &::before {
    content: '\e907';
  }
  color: $default;
  &:hover {
    color: $default-l;
  }
  &:active {
    color: $default-d;
  }
}

.pp-icon-check_double_active {
  &::before {
    content: '\e908';
  }
  color: $success;
  &:hover {
    color: $success-l;
  }
  &:active {
    color: $success-d;
  }
}

.pp-icon-checkmark-circle::before {
  content: '\e909';
}

.pp-icon-currency::before {
  content: '\e90a';
}

.pp-icon-document::before {
  content: '\e910';
}

.pp-icon-document-down::before {
  content: '\e911';
}

.pp-icon-evidence::before {
  content: '\e912';
}

.pp-icon-exit::before {
  content: '\e913';
}

.pp-icon-radio {
  &::before {
    content: '\e914';
  }
  color: $default;
  &:hover {
    color: $default-l;
  }
  &:active {
    color: $default-d;
  }
}

.pp-icon-radio_active {
  &::before {
    content: '\e915';
  }
  color: $success;
  &:hover {
    color: $success-l;
  }
  &:active {
    color: $success-d;
  }
}

.pp-icon-ticket::before {
  content: '\e916';
}

.pp-icon-upload::before {
  content: '\e917';
}

.pp-icon-close::before {
  content: '\e918';
}

.pp-icon-arrow-back::before {
  content: '\e919';
}

.pp-icon-print::before {
  content: '\e920';
}


@font-face
{ 
  font-family:icons-theme;
  src:url(icons-theme.eot?rjpq38);
  src:url(icons-theme.eot?rjpq38#iefix) format("embedded-opentype")
  ,url(icons-theme.ttf?rjpq38) format("truetype")
  ,url(icons-theme.woff?rjpq38) format("woff")
  ,url(icons-theme.svg?rjpq38#icons-theme) format("svg");
  font-weight:400;font-style:normal
}
