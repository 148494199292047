@import '../../assets/styles/mixins';

html, body {
  min-height: 100vh;
  font-family: 'Ubuntu', sans-serif;
  font-weight: $font-weight-default;
  font-size: 18px;
  overflow-x: hidden;
}

h1, h2, h3, h4, h5, h6 {
  // font-weight: $font-weight-bold;
  color: $info;
}

.col-mb {
  margin-bottom: $col-mb;
}

/* input validation */
.incorrect {
  .form-group input.form-control, .form-group textarea.form-control {
    border-color: $danger;
    &:hover, &:focus {
      box-shadow: 0 0 0 $input-btn-focus-width rgba($danger, 0.1);
    }
  }
}

.form-group {
  &.is-invalid input,
  &.is-invalid textarea {
    border-color: $danger;
    &:hover, &:focus {
      box-shadow: 0 0 0 $input-btn-focus-width rgba($danger, 0.1);
    }
  }

  &.is-valid input,
  &.is-valid textarea {
    border-color: $success;
    &:hover, &:focus {
      box-shadow: 0 0 0 $input-btn-focus-width rgba($success, 0.1);
    }
  }
}

input.form-control::placeholder {
  color: $gray-500;
}

/* card */
.pp-card {
  display: inline-flex;
  // align-items: center;
  background: $card-bg;
  box-shadow: 0 0.75rem 1.5rem 0 $card-border-color;
  padding: 0.75rem 1.5rem;
  color: $card-color;
}

.pp-card-header {
  padding: 1rem 0;
  border-bottom: 1px solid $gray-300;
  font-weight: $font-weight-bold;
}

.pp-card-body {
  padding: 1rem;
}

/* button */
.btn-default {
  background: transparent;
  border: 1px solid $gray-300;
  color: $primary;
}

.btn-secondary, .btn-secondary:hover {
  color: $primary;
}

.btn-success {
  color: $white;
}

.btn {
  text-transform: uppercase;
  &:focus, &.focus {
    box-shadow: none;
  }
}

.dropdown-toggle::after {
  margin-left: 0.75rem;
}

/* popver */
.popover {
  border: none;
  .arrow::before {
    border: none;
  }
  .popover-body {
    padding: 0;
  }
}

/* modal */
.modal-md {
  .modal-dialog {
    max-width: $modal-md;
    color: $primary;
  }
}

.modal-width-lg .modal-dialog {
  max-width: $modal-lg;
  color: $primary;
}

.modal-xl .modal-dialog {
  max-width: $modal-xl;
  color: $primary;
}

.modal-content {
  box-shadow: 0 8px 24px 0 $modal-shadow-color;
  border: none;
}

/* status */
.status {
  .paid {
    color: $status-paid;
  }
  .unpaid {
    color: $status-unpaid;

  }
  .cancelled {
    color: $status-cancelled;
  }
  .appealed {
    color: $status-appealed;
  }
}

.paid .status-value {
  color: $status-paid;
}

.unpaid .status-value {
  color: $status-unpaid;
}

.cancelled .status-value {
  color: $status-cancelled;
}

.appealed .status-value {
  color: $status-appealed;
}

.full-height-page-container {
  min-height: calc(100vh - #{$header-height} - #{$footer-height});

  @include media-breakpoint-down(md) {
    min-height: calc(100vh - #{$header-height} - #{$footer-md-height});
  }

  @include media-breakpoint-down(sm) {
    min-height: calc(100vh - #{$header-height} - #{$footer-sm-height});
  }

  @include media-breakpoint-down(xs) {
    min-height: calc(100vh - #{$header-xs-height} - #{$footer-xs-height});
  }
}

/* appeal */
.appeal {
  .modal-footer {
    padding: 1.75rem 0 0;
    justify-content: flex-start;

    .btn {
      padding: 0.5rem 2rem;
      font-size: 0.75rem;
    }
  }
}

/* carousel */
.carousel-item,
.carousel-inner,
.carousel {
  &:focus, &:hover {
    outline: none;
  }
}

/* hide arrows on numbers inputs */
input[type=number].hide-arrow::-webkit-inner-spin-button,
input[type=number].hide-arrow::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

@media (min-width: $grid-breakpoint-xxl) {
  .container {
    max-width: $container-xxl-max-width;
  }
}

/* blocked scroll */
.no-scroll {
  overflow: hidden;
}
