/* You can add global styles to this file, and also import other style files */
@import '_variables';
//@import '~bootstrap/scss/bootstrap';
@import 'assets/styles/bootstrap-grid';
@import '_base';
@import 'checkbox-radio';

body {
  margin: 0;
}
.card-header {
  background: none;
  border: 0;
}
.pp-icon-arrow-back::before {
  content: none !important;
}
// adyen
.payment-layout {
  padding-top: 30px;

}
.adyen-checkout__card__brands__brand-wrapper {
  line-height: 0.7rem;
}
.adyen-checkout__label__text {
  font-size: 18px;
}
.adyen-checkout__button {
  background-color: #2a5683 !important;
  width: 200px;
  margin-left: auto;
  margin-right: auto;
}
.adyen-checkout__spinner {
  border-bottom-color: #2a5683 !important;
  border-left-color: #2a5683 !important;
  border-right-color: #2a5683 !important;
}
// ------
.mz-flow {
  padding: 30px;
  color: #2a5683;
  min-height: 600px;
  max-width: 1020px;
  margin-top: 20px;
}
.mz-flow h1 {
  color: #2a5683;
  font-family: "Barlow Semi Condensed";
  font-weight: 700;
}
.mz-flow h3 {
  color: #2a5683;
}
.mz-flow .pp-icon-print {
  display: none;
}

.btn.btn--link-only, .btn.btn--link-back {
  color: #2a5683;
  background-color: transparent;
  padding: 0;
  position: absolute;
  margin-left: -40px;
  margin-top: -25px;
  font-size: 14px;
}
.btn.btn--link-only:after {
  content: "";
  font-family: icons-theme !important;
  font-size: 1.375rem;
  vertical-align: middle;
  border: 1px solid #00936c;
  border-radius: 50%;
  margin-left: 10px;
  transition: all 0.2s ease-out;
  position: relative;
  top: -1px;
}

.btn.btn--link-back:before {
  content: "";
  font-family: icons-theme !important;
  font-size: 1.375rem;
  vertical-align: middle;
  margin-left: 10px;
  transition: all 0.2s ease-out;
  position: relative;
  top: -1px;
  transform: rotate(180deg);
}
// REP overrides
.frontend-stepper {
  --frontend-stepper-success-color: #0abf53 !important;
}
