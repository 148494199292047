@import "@adyen/adyen-web/dist/adyen.css";

.threeDSChallengeIframeClass {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  background-color: white;
  border: 1px solid #444;
}
.payment-container .payment {
  max-width: 500px;
}
.payment-error {
    min-height:26px;
    color:rgb(177, 27, 27);
    max-width: 500px;
}
.payment-layout .btn-lg {
  min-width: 280px;
}
.adyen-checkout__input-wrapper  .adyen-checkout__input {
  padding-top:11px !important;
  padding-bottom:10px !important; 
  border-radius: 0px;
  height: 47px;
}

.preloader {
  display: block;
  height: 50px;
  left: 245px;
  position: absolute;
  top: 110px;
  width: 50px;
  z-index: 10;
}
.step-error-layout {
  
}