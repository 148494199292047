/*  checkbox and radiobutton*/

$active: #47cc52;
$default: #c1c9d9;

.form-check input[type='checkbox'],
.form-check input[type='radio'] {
  opacity: 0;
  position: absolute;
  visibility: hidden;
}

.form-check {
  padding: 0;
  min-width: 1rem;
  min-height: 1rem;

  .form-check-label {
    display: inline-flex;
    position: relative;
    line-height: 2rem;
    font-weight: 300;
    color: $primary;
    cursor: pointer;
    align-items: center;
  }

  .form-check-box,
  .form-check-box-double,
  .form-check-radio {
    display: inline-block;
    min-width: 25px;
    min-height: 1rem;
  }
  .form-check-box::before,
  .form-check-box::after,
  .form-check-box-double::after,
  .form-check-box-double::before,
  .form-check-radio::after,
  .form-check-radio::before {
    font-family: 'ppFont';
    position: absolute;
    cursor: pointer;
  }

  input[type='checkbox']:checked ~ span,
  input[type='radio']:checked ~ span {
    font-weight: $font-weight-bold;
  }

  input[type='checkbox'] + [class*='form-check-box'],
  input[type='radio'] + [class*='form-check-radio'] {
    color: $default;
    &:hover {
      color: lighten($default, 5%);
    }
    &:active {
      color: darken($default, 5%);
    }
  }

  input[type='checkbox']:checked + [class*='form-check-box'],
  input[type='radio']:checked + [class*='form-check-radio'] {
    color: $active;
    &:hover {
      color: lighten($active, 5%);
    }
    &:active {
      color: darken($active, 5%);
    }
  }

  .form-check-box::before {
    content: '\e904';
  }
  .form-check-box::after {
    content: '\e905';
    opacity: 0;
  }
  .form-check-box-double::before {
    content: '\e906';
  }
  .form-check-box-double::after {
    content: '\e907';
    opacity: 0;
  }
  .form-check-radio::before {
    content: '\e914';
  }
  .form-check-radio::after {
    content: '\e915';
    opacity: 0;
  }

}

.form-check input[type='checkbox']:checked + [class*='form-check-box']::after,
.form-check input[type='radio']:checked + [class*='form-check-radio']::after {
  opacity: 1;
}

.form-check input[type='checkbox']:checked + [class*='form-check-box']::before,
.form-check input[type='radio']:checked + [class*='form-check-radio']::before {
  opacity: 0;
}

.form-control input[type='checkbox']:disabled + [class*='form-check-box']::before,
.form-control input[type='radio']:disabled + [class*='form-check-radio']::before {
  cursor: not-allowed;
}

.form-check .form-check-label input[type='checkbox']:disabled + [class*='form-check-box'],
.form-check .form-check-label input[type='radio']:disabled + [class*='form-check-radio'] {
  pointer-events: none !important;
}
